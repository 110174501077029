import { createStandaloneToast, ToastPosition } from '@chakra-ui/react'
import { theme } from '~ui/theme'
import { ReactNode } from 'react'

interface toastData {
    id: string | number
    title: string
    description?: string
    duration?: number
    icon?: ReactNode
    isClosable?: boolean
    position?: ToastPosition
    size?: string
    variant?: string
    status?: 'error' | 'info' | 'warning' | 'success' | 'loading' | undefined
}

const { toast } = createStandaloneToast({ theme })

export const openToast = (toastData: toastData) => {
    if (!toast.isActive(toastData!.id)) {
        return toast({
            id: toastData.id,
            title: toastData.title,
            status: toastData.status ?? 'error',
            isClosable: toastData.isClosable ?? true,
            position: toastData.position ?? 'top',
        })
    }
}

export const closeToast = (id: string | number) => {
    if (id) {
        toast.close(id)
    }
}

export const closeAllToast = () => {
    toast.closeAll()
}
