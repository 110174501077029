const baseStyle = {
    '.calendar': {
        mx: 'auto',
        bg: 'inherit',
        pb: '15px',
        color: 'brand.extraHeavy',
        px: '20px',
        display: {
            base: 'flex',
            '2xl': 'flex',
        },
        flexDirection: 'column',
        minWidth: {
            base: '351px',
            lg: '391px',
        },
        maxWidth: {
            base: '351px',
        },
        minHeight: '405px',
        height: 'fit-content',
        '.highlight': {
            bg: 'white',
            borderRadius: '50%',
            boxShadow: '0px 0px 0px 2px #000060 inset',
        },
        '.link': {
            cursor: 'pointer !important',
        },
        '.react-calendar__navigation': {
            textAlign: 'center',
            py: '22px',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            '.react-calendar__navigation__label': {
                width: '170px',
                flexGrow: '0 !important',
                fontSize: '20px',
                fontWeight: '600',
                textTransform: 'capitalize',
                cursor: 'default',
            },
            '.react-calendar__navigation__arrow': {
                flexGrow: '0',
            },
            '.react-calendar__navigation__next-button': {
                transform: 'rotate(-90deg)',
            },
            '.react-calendar__navigation__prev-button': {
                transform: 'rotate(90deg)',
                '.disabled': {
                    cursor: 'default',
                },
            },
        },
        '.react-calendar__month-view__weekdays ': {
            textAlign: 'center',
            textTransform: 'capitalize',
            fontWeight: '600',
            abbr: {
                textDecoration: 'none',
            },
        },
        '.react-calendar__month-view__weekdays__weekday': {
            pb: '14px',
        },
        '.react-calendar__tile': {
            maxWidth: '37px',
            my: '5px',
            py: '6px',
            mx: {
                base: '3px',
                lg: '6px',
            },
        },
        '.react-calendar__month-view__days__day--neighboringMonth': {
            opacity: '0.5',
        },
        '.react-calendar__tile--now': {
            fontWeight: '700',
        },
    },
}

const Calendar = {
    baseStyle,
}

export default Calendar
