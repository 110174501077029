import { SystemStyleFunction } from '@chakra-ui/theme-tools'
import { ComponentStyleConfig } from '@chakra-ui/react'
import { theme } from '~ui/theme'

const primaryVariant: SystemStyleFunction = (props) => {
    return {
        _checked: {
            color: 'brand.medium',
            borderColor: 'brand.medium',
            borderWidth: '2px',
        },
        _hover: {
            borderColor: 'brand.medium',
            borderWidth: '2px',
            color: 'brand.medium',
        },
        _active: {
            borderWidth: '3px',
        },
        cursor: 'pointer',
        bg: 'transparent',
        borderWidth: '1px',
        borderColor: 'grey.heavy',
        fontSize: '17px',
        fontWeight: 'normal',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        w: {
            base: '345px',
            md: '430px',
        },
        h: '54px',
        textAlign: 'center',
    }
}

const promptVariant: SystemStyleFunction = (props) => {
    return {
        ...theme.components.ToggleButton.variants.primary(props),
        _checked: {
            borderColor: 'white',
            borderWidth: '2px',
        },
        _hover: {
            borderColor: 'white',
            borderWidth: '2px',
            bg: 'grey.medium',
        },
        color: 'white',
        borderColor: 'white',
    }
}
const variants = {
    primary: primaryVariant,
    prompt: promptVariant,
}

const defaultProps = {
    variant: 'primary',
}

const baseStyle = {
    borderRadius: 5,
    fontFamily: 'roboto',
}

const ToggleButton: ComponentStyleConfig = {
    baseStyle,
    variants,
    defaultProps,
}

export default ToggleButton
