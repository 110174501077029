/**
 * Adjusts the shade of a given color by a specified amount.
 *
 * @param {string} color - The color to adjust, in hexadecimal format (e.g., "#54b946").
 *                        It can have 3 or 6 characters, with or without the "#" prefix.
 * @param {number} amount - The amount by which to adjust the color's shade. A negative
 *                        value will make the color darker, and a positive value will make
 *                        it lighter. The valid range for `amount` is from -255 to 255.
 * @returns {string} - The adjusted color in hexadecimal format.
 */
export const colorShade = (color: string, amount: number): string => {
    const colorRGB = color.replace(/^#/, '')
    if (color.length === 3) color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2]

    let match = colorRGB.match(/.{2}/g)
    let [r, g, b] = match?.map((i) => parseInt(i, 16) + amount) || []

    const R = Math.max(Math.min(255, r), 0).toString(16)
    const G = Math.max(Math.min(255, g), 0).toString(16)
    const B = Math.max(Math.min(255, b), 0).toString(16)

    return `#${(R.length < 2 ? '0' : '') + R}${(G.length < 2 ? '0' : '') + G}${(B.length < 2 ? '0' : '') + B}`
}
