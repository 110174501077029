import { SystemStyleFunction } from '@chakra-ui/theme-tools'
import { ComponentStyleConfig } from '@chakra-ui/react'

const primaryVariant: SystemStyleFunction = (props) => {
    return {
        number: {
            color: 'brand.medium',
            fontSize: {
                base: '24px',
            },
            fontWeight: 'bold',
        },
        label: {
            color: 'brand.extraHeavy',
            fontWeight: '400',
            fontSize: {
                base: '17px',
            },
        },
    }
}
const defaultProps = {
    variant: 'primary',
}

const variants = {
    primary: primaryVariant,
}

const baseStyle = {
    container: {
        fontFamily: 'roboto',
    },
}

const Stat: ComponentStyleConfig = {
    variants,
    baseStyle,
    defaultProps,
}

export default Stat
