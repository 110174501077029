import { NextApiResponse } from 'next'
import { ServerResponse } from 'http'

type Response = NextApiResponse | ServerResponse

export const parseCookie = (rawCookieString: string | undefined) => {
    if (!rawCookieString) {
        return {}
    }

    return rawCookieString
        .split(';')
        .map((v) => v.split('='))
        .reduce((acc: { [key: string]: string }, v) => {
            acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim())
            return acc
        }, {})
}

export const setSessionCookie = (res: Response, name: string, value = '') => {
    setCookieHeader(res, `${name}=${value}; Path=/`)
}

export const setCookie = (res: Response, name: string, value = '', expires: Date | null = null) => {
    if (expires === null) {
        expires = new Date()
        expires.setDate(expires.getDate() + 30) // yes.. 41 July is 10 August
    }

    setCookieHeader(res, `${name}=${value}; Path=/; Expires=${expires.toUTCString()}`)
}

const setCookieHeader = (res: Response, newCookie: string) => {
    const existingCookies = res.getHeader('Set-Cookie')

    res.setHeader(
        'Set-Cookie',
        existingCookies && Array.isArray(existingCookies) ? [...existingCookies, newCookie] : [newCookie]
    )
}

export const removeCookie = (res: Response, name: string) => {
    res.setHeader('Set-Cookie', `${name}=; Path=/; Max-Age=0;`)
}

export const cookieName = (name: string) => {
    const prefix = process.env.NEXT_PUBLIC_COOKIE_PREFIX
    return `${prefix}${name}`
}

export const cookieJSONValue = (value: object | string | number | null) => {
    return JSON.stringify(value).replace(
        /[\u007F-\uFFFF]/g,
        (chr) => '\\u' + ('0000' + chr.charCodeAt(0).toString(16)).substr(-4)
    )
}
