
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import '../styles/globals.css'
import type { AppProps } from 'next/app'
import { NextPage } from 'next'
import { ReactElement, ReactNode, StrictMode, useEffect } from 'react'
import { ChakraProvider, createStandaloneToast, extendTheme } from '@chakra-ui/react'
import { theme } from '~ui/theme'
import { ApolloProvider } from '@apollo/client'
import { apolloClient } from '~lib/graphql'
import App from 'next/app'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/bebas-neue/400.css'
import { initAnalytics } from '~components/analytics/init'
import { colorShade } from '~components/sielbleu/utils/colors'
import { getCookies } from 'cookies-next'

const { ToastContainer } = createStandaloneToast()

export type NextPageWithLayout<T = any> = NextPage<T> & {
    getLayout?: (page: ReactElement) => ReactNode
}

export type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout
    pageProps: any
    auth: any
}

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
    useEffect(() => {
        initAnalytics()
    }, [])

    const colorPrescriber = getCookies()?.color_brand

    const colorPalette: Record<string, string> =
        typeof colorPrescriber === 'string' && colorPrescriber && colorPrescriber !== '#0380CF'
            ? {
                  extraLight: colorShade(colorPrescriber, 230),
                  light: colorShade(colorPrescriber, 20),
                  medium: colorPrescriber,
                  heavy: colorShade(colorPrescriber, -20),
                  extraHeavy: colorShade(colorPrescriber, -95),
              }
            : {
                  extraLight: '#EBF3FF',
                  light: '#028ee6',
                  medium: '#0380CF',
                  heavy: '#0072c9',
                  extraHeavy: '#000060',
              }

    const mergedTheme = extendTheme({
        ...theme,
        colors: {
            ...theme.colors,
            brand: colorPalette,
        },
    })

    const getLayout = Component.getLayout ?? ((page) => page)

    return (
        // <StrictMode>
        <ApolloProvider client={apolloClient}>
            <ChakraProvider theme={mergedTheme}>
                <ToastContainer />
                {getLayout(<Component {...pageProps} />)}
            </ChakraProvider>
        </ApolloProvider>
        // </StrictMode>
    )
}

MyApp.getInitialProps = async (appContext: any) => {
    const appProps = await App.getInitialProps(appContext)
    return { ...appProps }
}

const __Page_Next_Translate__ = MyApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  