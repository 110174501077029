import { extendTheme } from '@chakra-ui/react'

import styles from './styles'

// Foundations
import * as foundations from './foundations'

// Components
import * as components from './sielbleu/components'

export const theme = extendTheme({
    ...foundations,
    components: {
        ...components,
    },
})

export default extendTheme(theme)
