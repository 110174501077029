const hoistNonReactStatics = require('hoist-non-react-statics')

module.exports = {
    locales: ['default', 'en', 'it', 'fr', 'de', 'pl', 'es', 'pt'],
    defaultLocale: 'fr',
    staticsHoc: hoistNonReactStatics,
    domains: [
        {
            domain: process.env.NEXT_PUBLIC_SIELBLEU_DOMAIN,
            // "default" is used to force prefixing url
            // @see https://nextjs.org/docs/advanced-features/i18n-routing#prefixing-the-default-locale
            defaultLocale: 'default',
            localeDetection: false,
        },
    ],
    pages: {
        '*': ['common'],
        '/_sites/pf-and-me': ['sielbleu'],
        '/_sites/bpco': ['sielbleu'],
        '/_sites/work': ['sielbleu'],
        '/_sites/respifil-apa': ['sielbleu'],
        '/_sites/school': ['school'],
        '/_sites/school/on-demand/[...slug]': ['school'],
        '/_sites/www': ['www'],
        '/_sites/petits-pas': ['sielbleu'],
        '/_sites/generic': ['sielbleu'],
        'rgx:^/_sites/sielbleu': ['sielbleu'],
    },
}
