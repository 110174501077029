import { SystemStyleFunction } from '@chakra-ui/theme-tools'
import { ComponentStyleConfig } from '@chakra-ui/react'

const registerVariant: SystemStyleFunction = (props) => {
    return {
        field: {
            h: '54px',
            border: '1px',
            borderColor: 'grey.heavy',
            color: 'black',
            _focus: {
                border: '2px',
                borderColor: 'brand.medium',
            },
        },
    }
}

const borderlessVariant: SystemStyleFunction = (props) => {
    return {
        field: {
            cursor: 'pointer',
            h: '54px',
            border: 'none',
            color: 'grey.heavy',
            _focus: {
                border: 'none',
            },
        },
    }
}

const variants = {
    register: registerVariant,
    borderless: borderlessVariant,
}

const defaultProps = {
    variant: 'register',
}

const baseStyle = {
    borderRadius: 5,
    fontFamily: 'roboto',
}

const Select: ComponentStyleConfig = {
    baseStyle,
    variants,
    defaultProps,
}

export default Select
