import { SystemStyleFunction } from '@chakra-ui/theme-tools'

const registerVariant: SystemStyleFunction = (props) => {
    return {
        h: '100%',
        mb: {
            base: '30px',
        },
        '.title': {
            textAlign: 'center',
            my: {
                base: '30px',
                md: '50px',
            },
            maxW: {
                base: '95%',
                md: '630px',
            },
        },
        '.subtitle': {
            mt: '15px',
        },
        '.prompt-container': {
            textAlign: {
                base: 'center',
                lg: 'left',
            },
            display: 'flex',
            flexDirection: 'column',
            rowGap: {
                base: '25px',
            },
            maxW: {
                base: '85%',
                md: '750px',
            },
            p: {
                color: 'brand.extraHeavy',
                fontSize: {
                    lg: '18px',
                },
                fontWeight: 400,
            },
            '.prompt-buttons': {
                mt: '25px',
                display: 'flex',
                flexDirection: {
                    base: 'column',
                    lg: 'row',
                },
                columnGap: '25px',
                rowGap: '25px',
            },
        },
        '.register-container': {
            position: 'relative',
            w: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: {
                base: 'space-between',
                md: 'flex-start',
            },
            '.button': {
                my: {
                    base: '20px',
                    md: '45px',
                },
            },
            form: {
                w: '100%',
            },
            '.suggestions': {
                position: 'absolute',
                bg: 'white',
                zIndex: 1,
                paddingLeft: '2Opx',
                fontSize: '14px',
                borderColor: 'black',
                width: {
                    base: '337px',
                    md: '430px',
                },
                top: '55px',
                '.city': {
                    cursor: 'pointer',
                    py: '5px',
                    paddingLeft: '17px',
                    _hover: {
                        color: 'grey.heavy',
                    },
                },
            },
            '.chakra-checkbox': {
                p: {
                    font: 'inherit',
                },
            },
            p: {
                a: {
                    textDecoration: 'underline',
                    _hover: {
                        textDecoration: 'none',
                    },
                },
            },
        },
        '.input-container': {
            w: '100%',
            maxW: {
                base: '345px',
                md: '430px',
            },
            input: {
                mb: '15px',
            },
            '.form-control': {
                mt: '-16px',
                '.helper': {
                    fontSize: ['12px', '12px', '13px'],
                    fontStyle: 'italic',
                },
            },
        },
        '.text-container': {
            w: '100%',
            maxW: {
                base: '345px',
                md: '430px',
            },
            color: 'brand.extraHeavy',
            fontSize: '17px',
            letterSpacing: 0,
            lineHeight: '20px',
            '.checkbox-container': {
                mt: '26px',
                fontWeight: '500',
            },
            '.buttons-container': {
                mt: '40px',
                w: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: {
                    base: 'column',
                    md: 'row',
                },
                '*': {
                    my: '12px',
                },
            },
        },
        '.textarea-container': {
            maxW: { base: '345px', md: '516px' },
            w: '100%',
            pos: 'relative',
            textarea: {
                h: '256px',
            },
            '.characters-left': {
                pos: 'absolute',
                right: '0',
                fontSize: '12px',
            },
        },
    }
}

const connectedVariant: SystemStyleFunction = (props) => {
    return {
        margin: '0 auto',
        maxW: {
            base: '90%',
            '2xl': '1400px',
        },
        '.title': {
            my: '16px',
        },
    }
}

const landingVariant: SystemStyleFunction = (props) => {
    return {}
}

const variants = {
    register: registerVariant,
    connected: connectedVariant,
    landing: landingVariant,
}

const Page = {
    variants,
}

export default Page
