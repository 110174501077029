const baseStyle = {
    '.container': {
        maxW: {
            base: '306px',
            md: '460px',
            lg: '660px',
        },
    },
    '.title': {
        textAlign: 'center',
    },
    '.close': {
        textAlign: 'right',
        cursor: 'pointer',
        color: 'white',
        fontSize: '20px',
        position: 'absolute',
        top: '10px',
        right: '10px',
        borderRadius: '50%',
        w: '38px',
        h: '38px',
        bg: 'grey.light',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ':hover': {
            opacity: 0.5,
        },
    },
    '.carousel': {
        maxWidth: { base: '280px' },
        h: '195px',
        m: '0 auto',
        '.carousel-slider': {
            overflow: 'visible',
        },
    },

    '.carousel-root': {
        p: '20px',
    },
    '.control-dots': {
        display: 'flex',
        justifyContent: 'center',
        mt: '50px',
    },
    '.custom-dot': {
        w: '10px',
        h: '10px',
        borderRadius: '50%',
        zIndex: '1',
        cursor: 'pointer',
        mx: '6px',
    },
    '.slide': {
        textAlign: 'center',
        maxW: {
            base: '280px',
        },
        w: {
            base: '240px',
            // lg: '300px',
        },
        '.description': {
            color: 'brand.heavy',
            fontWeight: '500',
            fontSize: {
                base: '14px',
                lg: '16px',
            },
        },
        '.icon': {
            w: '100px',
        },
    },
    '.product-container': {
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: {
            base: '20px',
            lg: '30px',
        },
    },
    '.product': {
        textAlign: 'center',
        borderRadius: '15px',
        cursor: {
            base: 'pointer',
            lg: 'default',
        },
        p: {
            base: '20px 26px',
            lg: '25px 90px',
        },
        bg: 'white',
    },
    '.info': {
        textAlign: 'center',
        color: 'brand.heavy',
        fontSize: '14px',
        maxW: {
            base: '306px',
            lg: '100%',
        },
        p: '2px',
    },
}

const ProductPage = {
    baseStyle,
}

export default ProductPage
