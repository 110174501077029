const sielbleuBlue = {
    extraLight: '#EBF3FF',
    light: '#028ee6',
    medium: '#0380CF',
    heavy: '#0072c9',
    extraHeavy: '#000060',
    greyBlue: '#0B3856',
}

const yellow = {
    medium: '#FEC51B',
}

const grey = {
    extraLight: '#DEDEDE',
    light: '#CECECE',
    medium: '#8F8F8F',
    heavy: '#6E6E6E',
    extraHeavy: '#3E3E3E',
    promptWorkoutKit: '#737374',
}

const colors = {
    brand: sielbleuBlue,
    grey,
    yellow,
}

export default colors
