const baseStyle = {
    '.lock': {
        position: 'absolute',
        top: {
            base: 2,
            lg: 5,
        },
        right: 5,
        zIndex: 2,
    },
    '.container': {
        mb: '24px',
        display: 'flex',
        justifyContent: {
            lg: 'space-around',
        },
        flexDirection: {
            base: 'column',
            lg: 'row',
        },
        alignItems: {
            base: 'center',
            lg: 'flex-start',
        },
        rowGap: {
            base: '15px',
            lg: '20px',
        },
        '.session': {
            position: 'relative',
            textShadow: '0 0 1em rgba(0,0,0,0.7)',
            alignSelf: {
                lg: 'stretch',
            },
            maxWidth: {
                base: '372px',
                lg: '1010px',
            },
            width: {
                base: '100%',
                lg: '1010px',
            },
            mr: {
                lg: '20px',
            },
            h: {
                base: '390px',
                lg: 'auto',
            },
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            color: 'white',
            cursor: 'pointer',
            backgroundColor: 'brand.light',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            '.text': {
                padding: '20px',
            },
            '.title-session': {
                fontSize: ['32px', '32px', '32px', '42px'],
                fontWeight: 600,
                color: 'white',
            },
            '.description-session': {
                fontSize: ['18px', '18px', '18px', '22px'],
                mb: '20px',
                '.name-session': {
                    fontWeight: 'bold',
                },
            },
        },
        '.progression': {
            w: '100%',
            h: '10px',
            position: 'relative',
            '.progression-bar': {
                backgroundColor: 'rgba(255, 255, 255, 0.25)',
                w: '100%',
                h: '100%',
            },
            '.progression-bar-filled': {
                backgroundColor: 'brand.heavy',
                h: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
            },
        },
        '.aside': {
            maxWidth: {
                base: '372px',
                lg: 'none',
            },
            width: {
                base: '100%',
                lg: 'auto',
            },
            '.contact': {
                img: {
                    base: {
                        display: 'block',
                    },
                    lg: {
                        display: 'none',
                    },
                    '2xl': {
                        display: 'block',
                    },
                },
                maxWidth: {
                    base: '372px',
                    lg: '391px',
                },
                p: '20px',
                bg: 'brand.extraLight',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mb: {
                    base: '15px',
                    lg: '20px',
                },
                color: 'brand.extraHeavy',
                borderRadius: '5px',
                h2: {
                    m: '5px',
                },
                button: {
                    mt: '10px',
                    color: 'brand.extraHeavy',
                    borderColor: 'brand.extraHeavy',
                },
            },
            '.calendar-container': {
                width: {
                    base: '100%',
                    lg: 'auto',
                },
                borderRadius: '5px',
                bg: 'brand.extraLight',
                '.today': {
                    boxShadow: '0px 0px 0px 2px var(--chakra-colors-brand-light) inset',
                },
                '.react-calendar__tile': {
                    cursor: 'default',
                },
                '.done': {
                    bg: 'grey.extraLight',
                    borderRadius: '50%',
                    abbr: {
                        display: 'none',
                    },
                },
            },
        },
    },
    '.ads': {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '13px',
        width: {
            base: '100%',
            lg: '312px',
        },
        alignItems: 'center',
        '.ad': {
            maxWidth: {
                base: '372px',
                sm: 'none',
            },
            width: {
                base: '100%',
                lg: '312px',
            },
            height: {
                base: '201px',
            },
            mt: {
                lg: '18px',
            },
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            color: 'white',
            cursor: 'pointer',
            backgroundColor: 'brand.light',
            backgroundSize: 'cover',
            backgroundPosition: {
                base: 'center center',
                sm: 'top center',
                lg: 'center center',
            },
            textDecoration: 'none',
            outline: 'none',
            '.text': {
                padding: '20px',
                fontSize: {
                    base: '18px',
                },
                fontWeight: 600,
                color: 'white',
            },
        },
    },
    '.themes': {
        display: 'flex',
        columnGap: {
            base: '8px',
        },
        rowGap: {
            base: '18px',
            lg: '22px',
        },
        flexWrap: 'wrap',
        overflowX: 'auto',
        whiteSpace: 'wrap',
        mt: {
            base: '18px',
        },
        '@media screen and (min-width: 320px) and (max-width: 768px)': {
            whiteSpace: 'nowrap',
            flexWrap: 'nowrap',
            overflowX: 'auto',
        },
        justifyContent: {
            base: 'flex-start',
            lg: 'flex-start',
        },
        '.tag': {
            display: 'flex',
            minWidth: 'auto',
        },
    },
    '.recommendations': {
        maxWidth: {
            base: '372px',
            sm: 'none',
        },
        width: {
            base: '100%',
        },
        h2: {
            fontSize: {
                md: '25px',
                lg: '35px',
            },
        },

        '.recommendation-tiles': {
            display: 'flex',
            columnGap: {
                base: '14px',
                lg: '26px',
            },
            rowGap: {
                base: '18px',
                lg: '22px',
            },
            flexWrap: 'wrap',
            mt: {
                base: '18px',
            },
            pb: {
                base: 0,
                lg: '55px',
            },
            justifyContent: {
                base: 'space-between',
                lg: 'flex-start',
            },
            '.recommendation': {
                textShadow: '0 0 1em rgba(0,0,0,0.7)',
                position: 'relative',
                mb: '0',
                _before: {
                    content: '""',
                    display: 'block',
                    pt: '100%',
                },
                maxWidth: {
                    base: '165px',
                    lg: '290px',
                    xl: '333px',
                },
                w: {
                    base: 'calc(100% / 2 - 10px)',
                    lg: 'calc(100% / 4 - 20px)',
                },
                backgroundColor: 'brand.light',
                backgroundSize: 'cover',
                backgroundPosition: {
                    base: 'center center',
                    sm: 'top center',
                    lg: 'center center',
                },
                borderRadius: '5px',
                color: 'white',
                p: {
                    base: '10px',
                    lg: '20px',
                },
                display: 'flex',
                alignItems: 'flex-end',
                '.text': {
                    zIndex: 2,
                    position: 'relative',
                    fontWeight: 'lighter',
                    fontSize: {
                        base: '14px',
                        lg: '18px',
                    },
                    '.label': {
                        fontWeight: 500,
                        fontSize: {
                            base: '19px',
                            lg: '25px',
                        },
                    },
                },
            },
            '.lg': {
                maxWidth: '100%',
                maxHeight: '100%',
                w: {
                    base: '100%',
                    lg: 'calc(100% / 2 - 26px)',
                },
            },
            '.done': {
                _after: {
                    content: '""',
                    position: 'absolute',
                    borderRadius: '5px',
                    top: 0,
                    left: 0,
                    w: '100%',
                    h: '100%',
                    backgroundColor: 'rgba(0, 114, 201, 0.6)',
                    zIndex: 1,
                },
                '.check': {
                    position: 'absolute',
                    top: {
                        base: 2,
                        lg: 5,
                    },
                    right: 5,
                    zIndex: 2,
                },
            },
        },
    },
    '.sw-recommendations': {
        '.recommendation-tiles': {
            '.recommendation': {
                height: {
                    base: '201px',
                    lg: '433px',
                },
                mb: 0,
            },
        },
    },
}

const AdaptedSessions = {
    baseStyle,
}

export default AdaptedSessions
