const baseStyle = {
    parts: ['content'],
    modal: {
        borderRadius: '5px',
        overflow: 'hidden',
    },
    dialog: {
        fontSize: '14px',
        lineHeight: '20px',
    },
    header: {
        fontSize: '20px',
        marginTop: '15px',
    },
    footer: {
        borderRadius: '0 0 5px 5px',
        marginTop: '10px',
        bg: 'grey.extraLight',
        p: {
            fontSize: '14px',
            color: 'grey.heavy',
        },
        button: {
            marginLeft: '20px',
        },
    },
}

const Modal = {
    baseStyle,
}

export default Modal
