const baseStyle = {
    '.lock': {
        position: 'absolute',
        top: {
            base: 2,
            lg: 5,
        },
        right: 5,
        zIndex: 2,
    },
    '.container': {
        display: 'flex',
        justifyContent: {
            lg: 'space-around',
        },
        flexDirection: {
            base: 'column-reverse',
            md: 'row',
        },
        alignItems: {
            base: 'center',
            md: 'flex-start',
        },
        '.sessions': {
            maxWidth: '1010px',
            width: {
                md: '1010px',
            },
            mr: {
                md: '20px',
            },
            '.live-session': {
                position: 'relative',
                maxWidth: {
                    base: '351px',
                    md: 'none',
                    xl: '986px',
                },
                width: {
                    base: '351px',
                    md: '100%',
                },
                display: {
                    xl: 'flex',
                },
                bg: 'brand.extraLight',
                marginBottom: '20px',
                borderRadius: '5px',
                overflow: 'hidden',
                '.session-image': {
                    maxWidth: {
                        xl: '231px',
                    },
                    position: 'relative',
                    '.icon': {
                        top: '50%',
                        left: '50%',
                        position: 'absolute',
                        transform: 'translate(-50%, -50%)',
                    },
                    img: {
                        h: {
                            base: '140px',
                            xl: '100%',
                        },
                        w: {
                            base: '100%',
                        },
                        objectFit: 'cover',
                        filter: 'brightness(0.7)',
                    },
                },
                '.session-text': {
                    flexGrow: '2',
                    color: 'brand.extraHeavy',
                    px: '15px',
                    '.title': {
                        fontSize: '20px',
                        fontWeight: '600',
                    },
                    '.description': {
                        lineHeight: '20px',
                        my: '16px',
                        mr: {
                            lg: '20px',
                        },
                    },
                    '.date': {
                        textTransform: 'capitalize',
                        color: 'brand.light',
                    },
                    '.cta': {
                        color: 'brand.medium',
                        marginTop: {
                            base: '10px',
                            lg: '35px',
                        },
                        marginBottom: '20px',
                        '.attendee': {
                            display: {
                                xl: 'flex',
                            },
                            alignItems: {
                                xl: 'center',
                            },
                            justifyContent: {
                                xl: 'space-around',
                            },
                            button: {
                                fontSize: '18px',
                            },
                        },
                        '.registered': {
                            display: 'flex',
                            alignItems: 'center',
                        },
                        '.text': {
                            fontSize: '18px',
                            fontWeight: 'bold',
                            py: {
                                base: '16px',
                                lg: '12px',
                            },
                            pr: {
                                base: '16px',
                                lg: '12px',
                            },
                        },
                        '.spinner-unsubscribe': {
                            mt: '20px',
                            ml: '10px',
                            color: 'brand.medium',
                        },
                        '.red': {
                            color: 'red.500',
                            cursor: 'pointer',
                            _hover: {
                                color: 'red.400',
                            },
                        },
                    },
                },
            },
        },
        '.calendar-container': {
            mb: '20px',
            borderRadius: '5px',
            bg: 'brand.extraLight',
            '.header': {
                px: '20px',
                py: '20px',
                h2: {
                    fontSize: '20px',
                    fontWeight: '600',
                },
                borderBottom: '1px',
                borderColor: 'grey.light',
            },
            '.footer': {
                px: '20px',
                borderTop: '1px',
                mb: '15px',
                borderColor: 'grey.light',
                '.calendar-session': {
                    mt: '15px',
                    color: 'brand.extraHeavy',
                    fontSize: '14px',
                    '.calendar-session-title': {
                        fontWeight: 'bold',
                        pb: '2px',
                    },
                },
            },
        },
    },
}

const LiveSessions = {
    baseStyle,
}

export default LiveSessions
