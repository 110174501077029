import { tagAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys)

const solidVariant = definePartsStyle({
    container: {
        bg: 'brand.medium',
        color: 'white',
        transition: 'background-color .25s ease-in',
        _hover: {
            opacity: 0.7,
        },
    },
})

const outlineVariant = definePartsStyle({
    container: {
        bg: 'white',
        color: 'brand.medium',
        borderWidth: 2,
        borderColor: 'brand.medium',
        boxShadow: 0,
        transition: 'background-color .25s ease-in',
        _hover: {
            opacity: 0.7,
        },
    },
})

const Tag = defineMultiStyleConfig({
    variants: {
        solid: solidVariant,
        outline: outlineVariant,
    },
})

export default Tag
