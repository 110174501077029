import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/fr'
import 'dayjs/locale/it'
import 'dayjs/locale/es'
import 'dayjs/locale/en'

export const FULL_DATE_FORMAT = 'DD MMMM YYYY' // 13 October 1985
export const SHORT_DATE_FORMAT = 'DD MMM YYYY' // 13 Oct. 1985
export const EUROPEAN_DATE_FORMAT = 'DD/MM/YYYY' // 13/10/1985

dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
const userTz = dayjs.tz.guess()

export const localeDateFormat = (date: string, userLocale: string, dateFormat: string): string => {
    return dayjs.utc(date).tz(userTz).locale(userLocale).format(dateFormat)
}

export const adaptedSessionDateFormat = (date: string, locale: string, t: any) => {
    const dayStart = dayjs.utc(date).tz(userTz).hour(0).minute(0).second(0).locale(locale)
    const today = dayjs().tz(userTz).hour(0).minute(0).second(0).locale(locale)

    if (today.isSame(dayStart, 'day')) {
        return t('sielbleu.adapted-sessions.today', { date: dayStart.format('LL') })
    }
    const relativeDate = dayStart.from(today)
    return relativeDate.charAt(0).toUpperCase() + relativeDate.slice(1)
}

export const liveSessionDateFormat = (start: string, end: string, locale: string) => {
    const dayStart = dayjs.utc(start).tz(userTz).locale(locale)
    const dayEnd = dayjs.utc(end).tz(userTz).locale(locale)
    return {
        dayName: dayStart.format('dddd'), // saturday
        date: dayStart.format('LL'), // October 26, 1985
        startHour: dayStart.format('LT'), // 10:04 PM
        endHour: dayEnd.format('LT'),
    }
}

export const europeanDateFormat = (date: string) => {
    return dayjs(new Date(date)).format(EUROPEAN_DATE_FORMAT)
}

export const ISOLocalDateFormat = (timezone: string) => {
    // e.g: 2023-06-07T10:23:08+02:00
    return dayjs().utc().tz(timezone).format()
}

export function getDurationInMinutes(seconds: number) {
    const duration = Math.round(seconds / 60)
    return duration <= 0 ? 1 : duration
}
