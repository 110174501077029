import { SystemStyleFunction } from '@chakra-ui/theme-tools'
import { theme } from '~ui/theme'

const registerVariant: SystemStyleFunction = (props) => {
    return {
        '.header': {
            w: '100%',
            borderBottom: '2px',
            borderColor: 'grey.light',
            display: 'flex',
            justifyContent: {
                base: 'space-between',
                md: 'space-between',
            },
        },
        '.logo': {
            cursor: 'pointer',
            m: 2.5,
            w: {
                base: 139,
                md: 163,
            },
        },
        '.locale': {
            color: 'grey.heavy',
            marginRight: {
                base: '20px',
                md: '30px',
            },
        },
        '.header-register-cta': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '.sign-link': {
                display: 'flex',
                alignItems: 'center',
                marginRight: {
                    base: '20px',
                    md: '30px',
                },
            },
        },
    }
}

const connectedVariant: SystemStyleFunction = (props) => {
    return {
        ...theme.components.Header.variants.register(props),
        '.header': {
            w: '100%',
            borderBottom: '2px',
            borderColor: 'grey.light',
            display: 'flex',
            justifyContent: {
                base: 'space-between',
            },
        },
        '.nav': {
            display: 'flex',
            my: '15px',
            justifyContent: {
                base: 'center',
                md: 'center',
            },
            alignItems: {
                md: 'center',
            },

            '.nav-bar-links': {
                display: {
                    base: 'none',
                    md: 'flex',
                },
                li: {
                    mx: {
                        md: '11px',
                        lg: '22px',
                    },
                },
            },
            '.nav-menu-profile': {
                mx: '16px',
                display: {
                    base: 'none',
                    md: 'flex',
                },
                '.name': {
                    display: {
                        sm: 'none',
                        lg: 'block',
                    },
                },
            },
            '.nav-menu-button': {
                display: {
                    base: 'flex',
                    md: 'none',
                },
                alignItems: 'center',
                marginRight: '22px',
                '.text': {
                    color: 'black',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    marginLeft: '10px',
                    marginTop: '4px',
                },
            },
            '.nav-menu-links': {
                display: {
                    md: 'none',
                },
                hr: {
                    borderColor: 'grey.light',
                    width: '100%',
                    marginBottom: '8px',
                },
            },
        },
    }
}

const landingVariant: SystemStyleFunction = (props) => {
    return {
        ...theme.components.Header.variants.connected(props),
        '.header': {
            m: '0 auto',
            px: {
                md: '35px',
                '2xl': '0px',
            },
            maxW: {
                base: '100%',
                '2xl': '1400px',
            },
            display: 'flex',
            justifyContent: {
                base: 'space-between',
            },
        },
        '.locale': {
            marginTop: {
                base: '5px',
                md: 0,
            },
        },
        '.nav-bar-links': {
            display: {
                base: 'none',
                md: 'flex',
            },
            li: {
                mx: {
                    md: '3px !important',
                    lg: '18px !important',
                },
            },
            'a:has(div)': {
                _hover: {
                    color: 'transparent',
                },
            },
        },
    }
}

const variants = {
    register: registerVariant,
    connected: connectedVariant,
    landing: landingVariant,
}

const baseStyle = {
    color: 'grey.medium',
}

const Header = {
    baseStyle,
    variants,
}

export default Header
