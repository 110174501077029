export const GREY_EXTRA_LIGHT = '#DEDEDE'
export const GREY_LIGHT = '#CECECE'
export const GREY_MEDIUM = '#8F8F8F'
export const GREY_HEAVY = '#6E6E6E'

export const BLUE_EXTRA_LIGHT = '#EBF3FF'
export const BLUE_LIGHT = '#028ee6'
export const BLUE_MEDIUM = '#0380CF'
export const BLUE_HEAVY = '#0072c9'
export const BLUE_EXTRA_HEAVY = '#000060'

export const WHITE = '#fff'

export const FPI = 'FPI'
export const BPCO = 'BPCO'
export const COURS_CO = 'COURS_CO'
export const RESPIFIL = 'RESPIFIL'
export const SIELBLEU = 'SIELBLEU'
export const WORK = 'WORK'
export const GENERIC = 'GENERIC'
export const ONLINE = 'ONLINE'

export const REGISTER = 'register'
export const CONNECTED = 'connected'
export const LANDING = 'landing'

export const CONTACT_MAIL = 'fizzup@sielbleu.org'
export const COURS_CO_MAIL = 'mescours@sielbleu.org'
export const FIZZUP_SIEL_BLEU_MAIL = 'fizzup@sielbleu.org'
export const DEFAULT_TIMEZONE = 'Europe/Paris'

export const LANDING_SUBDOMAIN = ['bpco', 'pf-and-me', 'copd', 'respifil-apa', 'work', 'online', 'generic']

export const NEWSLETTER_LANGUAGES = ['fr', 'es']
