const baseStyle = {
    '.container': {
        '.block': {
            color: 'grey',
            borderTop: '1px',
            borderTopColor: 'grey.light',
            display: 'flex',
            flexDirection: {
                base: 'column',
                md: 'row',
            },
            justifyContent: {
                md: 'space-between',
            },
            alignItems: {
                md: 'flex-start',
            },
            py: {
                base: '0px',
                md: '20px',
            },
            '.title': {
                width: {
                    md: '250px',
                    lg: '320px',
                },
                my: {
                    base: '20px',
                    md: '0px',
                },
            },
            '.items': {
                my: {
                    base: '15px',
                    md: '0px',
                },
                '.item': {
                    paddingBottom: {
                        base: '6px',
                        md: '10px',
                    },
                },
                '.update-item': {
                    paddingBottom: {
                        base: '10px',
                        md: '10px',
                    },
                    textAlign: {
                        md: 'right',
                    },
                    color: 'brand.medium',
                    cursor: 'pointer',
                    _hover: {
                        textDecoration: 'underline',
                    },
                },
                '.delete-item': {
                    color: 'error',
                    cursor: 'pointer',
                    _hover: {
                        textDecoration: 'underline',
                    },
                },
            },
            '.shift': {
                flexGrow: '1',
            },
        },
        '.edit-block': {
            borderTop: '1px',
            borderTopColor: 'grey.light',
        },
        '.edit-form': {
            w: '100%',
            maxW: {
                base: '345px',
                md: '430px',
            },
            '.input-container': {
                w: '100%',
                position: 'relative',
                '.suggestions': {
                    position: 'absolute',
                    bg: 'white',
                    zIndex: 1,
                    paddingLeft: '2Opx',
                    fontSize: '14px',
                    borderColor: 'black',
                    width: {
                        base: '337px',
                        md: '430px',
                    },
                    top: '55px',
                    '.city': {
                        cursor: 'pointer',
                        py: '5px',
                        paddingLeft: '17px',
                        _hover: {
                            color: 'grey.heavy',
                        },
                    },
                },
            },
            form: {
                w: '100%',
                display: 'flex',
                alignItems: 'flex-start',
                '.select-countries': {
                    mb: '20px',
                },
            },
            input: {
                marginBottom: '22px',
            },
        },
    },
}

const Settings = {
    baseStyle,
}

export default Settings
