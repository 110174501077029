import { ComponentStyleConfig } from '@chakra-ui/react'

const primaryVariant = {
    color: 'brand.medium',
    fontWeight: 'bold',
    fontSize: ['25px', '25px', '35px'],
}

const whiteTitleVariant = {
    color: '#FFFFFF',
    textTransform: 'uppercase',
    fontSize: ['25px', '25px', '35px'],
    fontWeight: '900',
}
const subtitleVariant = {
    color: 'grey.heavy',
    fontSize: '20px',
    fontWeight: 'normal',
}

const tileVariant = {
    color: 'brand.extraHeavy',
    fontSize: ['23px', '23px', '23px'],
}

const cardVariant = {
    color: 'brand.medium',
    fontSize: ['20px', '20px', '20px'],
}

const variants = {
    primary: primaryVariant,
    subtitle: subtitleVariant,
    tile: tileVariant,
    card: cardVariant,
    whiteTitle: whiteTitleVariant,
}

const defaultProps = {
    variant: 'primary',
}

const baseStyle = {
    textTransform: 'none',
    fontFamily: 'roboto',
}

const Heading: ComponentStyleConfig = {
    baseStyle,
    defaultProps,
    variants,
}

export default Heading
