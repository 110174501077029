import { SystemStyleFunction } from '@chakra-ui/theme-tools'
import { ComponentStyleConfig } from '@chakra-ui/react'
import { theme } from '~ui/theme'

const primaryVariant: SystemStyleFunction = (props) => {
    return {
        fontWeight: 'bold',
        bg: 'brand.medium',
        color: 'white',
        fontSize: {
            base: '16px',
            md: '20px',
        },
        textTransform: 'uppercase',
        transition: 'background-color .25s ease-in',
        px: {
            base: '16px',
            md: '26px',
        },
        h: {
            base: '45px',
            md: '54px',
        },
        _hover: {
            bg: 'brand.heavy',
            _disabled: {
                bg: 'grey.light',
            },
        },
        _disabled: {
            opacity: 1,
            bg: 'grey.light',
            color: 'grey.heavy',
        },
        _loading: {
            opacity: 1,
            bg: 'brand.medium',
            color: 'white',
        },
    }
}

const dangerVariant: SystemStyleFunction = (props) => {
    return {
        ...theme.components.Button.variants.primary(props),
        bg: 'red.500',
        _hover: {
            bg: 'red.400',
        },
        _focus: {
            boxShadow: 'none',
        },
    }
}

const outlineVariant: SystemStyleFunction = (props) => {
    return {
        ...theme.components.Button.variants.primary(props),
        bg: 'inherit',
        color: 'brand.medium',
        borderColor: 'brand.medium',
        _hover: {
            bg: 'blue.50',
        },
    }
}

const yellowVariant: SystemStyleFunction = (props) => {
    return {
        ...theme.components.Button.variants.primary(props),
        bg: 'yellow.medium',
        color: 'brand.medium',
        borderColor: 'brand.medium',
        _hover: {
            bg: 'blue.50',
        },
    }
}

const variants = {
    primary: primaryVariant,
    danger: dangerVariant,
    outline: outlineVariant,
    yellow: yellowVariant,
}

const defaultProps = {
    variant: 'primary',
}

const baseStyle = {
    borderRadius: 5,
    fontFamily: 'roboto',
}

const Button: ComponentStyleConfig = {
    baseStyle,
    variants,
    defaultProps,
}

export default Button
