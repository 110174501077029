import { SystemStyleFunction } from '@chakra-ui/theme-tools'
import { ComponentStyleConfig } from '@chakra-ui/react'

const infoVariant: SystemStyleFunction = (props) => {
    return {
        '.alert': {
            bg: 'brand.extraLight',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        },
        '.container': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: '2',
        },
        '.content': {
            color: 'brand.extraHeavy',
            fontSize: ['14px', '14px', '16px'],
        },
        '.close': {
            color: 'grey',
        },
    }
}

const variants = {
    info: infoVariant,
}

const Banner: ComponentStyleConfig = {
    variants,
}

export default Banner
