import { SystemStyleFunction } from '@chakra-ui/theme-tools'
import { ComponentStyleConfig } from '@chakra-ui/react'

const registerVariant: SystemStyleFunction = (props) => {
    return {
        field: {
            h: '54px',
            border: '1px',
            borderColor: 'grey.heavy',
            color: 'black',
            _focus: {
                border: '2px',
                borderColor: 'brand.medium',
            },
            _placeholder: {
                color: 'grey.heavy',
            },
            _invalid: {
                border: '2px',
                borderColor: 'error',
            },
        },
        element: {
            h: '54px',
        },
    }
}

const variants = {
    register: registerVariant,
}

const defaultProps = {
    variant: 'register',
}

const baseStyle = {
    borderRadius: 5,
    fontFamily: 'roboto',
}

const Input: ComponentStyleConfig = {
    baseStyle,
    variants,
    defaultProps,
}

export default Input
