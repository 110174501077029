const baseStyle = {
    '.title': {
        textAlign: 'center',
        my: {
            base: '30px',
            md: '50px',
        },
        maxW: {
            base: '95%',
            md: '630px',
        },
    },
    '.container': {
        '.checkbox-container': {
            lineHeight: '5',
            color: 'brand.extraHeavy',
            maxW: {
                base: '95%',
                md: '430px',
            },
            '.subtitle': {
                maxW: {
                    base: '100%',
                    md: '450px',
                },
                fontSize: '17px',
                color: 'grey.heavy',
                mb: '17px',
            },
            '.checkbox-block': {
                mb: '35px',
                '.list': {
                    mt: '12px',
                    ml: '25px',
                },
            },
            '.chakra-checkbox': {
                alignItems: 'flex-start',
            },
            '.chakra-checkbox__control': {
                mt: '5px',
            },
            '.link': {
                display: 'block',
                ml: '25px',
                mt: '5px',
                fontSize: '15px',
                color: 'grey.medium',
                fontStyle: 'italic',
                textDecoration: 'underline',
                cursor: 'pointer',
                _hover: {
                    color: 'grey.extraHeavy',
                },
            },
        },
    },
}

const MedicalConsent = {
    baseStyle,
}

export default MedicalConsent
