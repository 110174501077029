import { SystemStyleFunction } from '@chakra-ui/theme-tools'

const landingVariant: SystemStyleFunction = (props) => {
    return {
        '.footer': {
            w: '100%',
            bg: 'brand.extraHeavy',
        },
        '.footer-container': {
            bg: '#0B3856',
            m: '0',
            display: 'flex',
            flexDirection: {
                base: 'column',
            },
            alignItems: 'center',
            py: '25px',
            px: '70px',
            w: '100%',

            '.follow-us': {
                display: 'flex',
                w: '100%',
                flexDirection: {
                    base: 'column',
                    lg: 'row',
                },
                alignItems: {
                    base: 'center',
                },
                justifyContent: {
                    lg: 'flex-start',
                },
                '.logo-footer': {
                    m: {
                        base: '25px',
                        lg: '0',
                    },
                    img: {
                        w: '129px',
                    },
                },
                '.social': {
                    fontSize: '18px',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: {
                        base: 'column',
                        lg: 'row',
                    },
                    ml: {
                        lg: '25px',
                    },
                    '*': {
                        mx: {
                            base: '2px',
                            lg: '5px',
                        },
                    },
                    '.icons': {
                        mt: {
                            base: '15px',
                            lg: 0,
                        },
                        a: {
                            display: 'inline-block',
                            borderRadius: '8px',
                            bgColor: 'white',
                            h: '36px',
                            w: '36px',
                            lineHeight: '36px',
                            textAlign: 'center',
                            m: 1,
                        },
                        path: {
                            fill: '#0B3856',
                        },
                        'a:hover path': {
                            fill: '#ffffff',
                        },
                        '.facebook:hover': {
                            bgColor: '#3b5998 !important',
                        },
                        '.twitter:hover': {
                            bgColor: '#000000 !important',
                        },
                        '.linkedin:hover': {
                            bgColor: '#006fa6 !important',
                        },
                        '.youtube:hover': {
                            bgColor: '#E62117 !important',
                        },
                        '.instagram:hover': {
                            bgColor: '#e70062 !important',
                        },
                    },
                },
            },
            hr: {
                my: {
                    base: '40px',
                    lg: '20px',
                },
                border: '1px solid white',
            },

            '.links': {
                fontSize: '17px',
                w: '100%',
                display: 'flex',
                flexDirection: {
                    base: 'column',
                    lg: 'row',
                },
                alignItems: 'center',
                justifyContent: {
                    lg: 'space-around',
                },
                a: {
                    mt: {
                        lg: '5px',
                    },
                    mb: {
                        base: '25px',
                        lg: '25px',
                    },
                    _hover: {
                        cursor: 'pointer',
                        textDecoration: 'underline !important',
                    },
                },
            },
        },
    }
}

const variants = {
    landing: landingVariant,
}

const defaultProps = {
    variant: 'landing',
}

const baseStyle = {
    color: 'white',
}

const Footer = {
    baseStyle,
    variants,
    defaultProps,
}

export default Footer
