import { SystemStyleFunction } from '@chakra-ui/theme-tools'
const workoutKitVariant: SystemStyleFunction = (props) => {
    return {
        '.prompt': {
            position: 'fixed',
            bg: 'grey.promptWorkoutKit',
            top: 0,
            left: 0,
            overflow: 'auto',
            h: '100%',
            w: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 100,
            '.close': {
                position: 'absolute',
                right: '32px',
                top: '32px',
                width: '38px',
                height: '38px',
                cursor: 'pointer',
                borderRadius: '50%',
                bg: '#585858',
            },
            '.close:hover': {
                bg: 'grey.extraHeavy',
            },
            '.close:before, .close:after': {
                position: 'absolute',
                top: '8px',
                left: '18px',
                content: '" "',
                height: '22px',
                width: '2px',
                bg: 'white',
            },
            '.close:before': {
                transform: 'rotate(45deg)',
            },
            '.close:after': {
                transform: 'rotate(-45deg)',
            },
            '.content': {
                textAlign: 'center',
                '.title': {
                    color: 'white',
                    fontSize: {
                        base: '25px',
                        lg: '32px',
                    },
                },
                '.subtitle': {
                    color: 'white',
                    fontSize: '14px',
                    mt: '20px,',
                    mb: '20px',
                },
            },
        },
    }
}

const variants = {
    workoutKitStyle: workoutKitVariant,
}

const Prompt = {
    variants,
}

export default Prompt
