const baseStyle = {
    '.themes': {
        display: 'flex',
        columnGap: {
            base: '8px',
        },
        rowGap: {
            base: '18px',
            lg: '22px',
        },
        flexWrap: 'wrap',
        overflowX: 'auto',
        whiteSpace: 'wrap',
        mt: {
            base: '18px',
        },
        '@media screen and (min-width: 280px) and (max-width: 768px)': {
            whiteSpace: 'nowrap',
            flexWrap: 'nowrap',
            overflowX: 'auto',
        },
        justifyContent: {
            base: 'flex-start',
            lg: 'flex-start',
        },
        '.tag': {
            display: 'flex',
            minWidth: 'auto',
        },
    },
    '.category': {
        color: '#000060',
    },
    '.blocs': {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '10px',
        flexWrap: 'wrap',
        '.bloc': {
            backgroundColor: '#EBF3FF',
            borderRadius: '10px',
            opacity: '1',
            marginTop: '64px',
            textAlign: 'center',
            minWidth: '240px',
            maxWidth: { sm: '100%', md: '48%', xl: '327px' },
            flexBasis: '100%',
            '.icon': {
                margin: 'auto',
                position: 'relative',
                transform: 'translateY(-45px)',
                border: '7px solid #EBF3FF',
                borderRadius: '50%',
                padding: '7px',
                background: '#FFFFFF',
            },
            '.data': {
                paddingBottom: '54px',
                paddingX: '70px',
            },
            '@media screen and (min-width: 320px) and (max-width: 412px)': {
                minWidth: '160px',
                maxWidth: '100%',
            },
        },
        '@media screen and (max-width:1100px)': {
            flexWrap: 'wrap',
            minWidth: '40%',
        },
    },
    '.bar-chart': {
        backgroundColor: '#EBF3FF',
        borderRadius: '10px',
        opacity: '1',
        marginTop: '25px',
        padding: '20px',
        '.label': {
            color: 'brand.medium',
        },
    },
}
const Statistics = {
    baseStyle,
}

export default Statistics
