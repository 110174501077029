const baseStyle = {
    position: 'fixed',
    bg: 'grey.promptWorkoutKit',
    top: 0,
    left: 0,
    overflow: 'auto',
    h: '100%',
    w: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
    '.rhythm-container': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
    },
    '.congrats': {
        color: 'white',
    },
    '.workout-count': {
        my: '20px',
        bg: 'white',
        borderRadius: '50%',
        width: {
            base: '200px',
            md: '240px',
        },
        height: {
            base: '200px',
            md: '240px',
        },
        border: '12px solid white',
        boxShadow: 'inset 0 0 0 6px green',
        padding: '20px',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '.animation': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
            base: '250px',
            md: '310px',
        },
        zIndex: 4,
    },
    '.counter-box': {
        position: 'relative',
        color: 'grey.heavy',
        bg: 'transparent',
        fontSize: {
            base: '60px',
            md: '80px',
        },
        fontWeight: 'bold',
        fontFamily: 'Bebas Neue',
        zIndex: 5,
        span: {
            flexShrink: 1,
        },
    },
    '.duration': {
        textAlign: 'center',
        fontSize: '20px',
        my: '20px',
        '.duration-unit': {
            fontWeight: 'bold',
        },
    },
}

const Rhythm = {
    baseStyle,
}

export default Rhythm
