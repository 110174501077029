const baseStyle = {
    '.user-programs-container': {
        mt: '40px',
        '.title': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        '.completed-programs-button': {
            maxWidth: '320px',
            h: '36px',
            fontSize: '15px',
            px: '16px',
        },
        '.program-card': {
            w: '100%',
            maxW: '690px',
            backgroundColor: 'brand.extraLight',
            borderRadius: '5px',
            boxShadow: 0,
            '.carousel-container': {
                maxWidth: { base: '340px', xl: '100%' },
                m: '0 auto',
            },
            '.carousel-root': {
                p: '20px',
            },
            '.control-dots': {
                display: 'flex',
                justifyContent: 'center',
            },
            '.poster': {
                h: '406px',
                borderRadius: '10px',
                backgroundColor: 'brand.medium',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                minW: '290px',
            },
            '.program-data': {
                w: '100%',
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'left',
                pl: { xl: '20px' },
            },
            '.program-name': {
                my: '10px',
                fontWeight: '500',
                fontSize: '24px',
                color: 'brand.extraHeavy',
            },
            '.custom-arrow': {
                width: '42px',
                borderRadius: '8px',
                border: '1px',
                borderColor: 'gray.200',
                backgroundColor: 'white',
                position: 'absolute',
                bottom: '0px',
                zIndex: '1',
                cursor: 'pointer',
            },
            '.custom-dot': {
                w: '14px',
                h: '14px',
                borderRadius: '50%',
                zIndex: '1',
                cursor: 'pointer',
                mx: '6px',
            },
        },
    },

    '.completed-program-card': {
        maxW: '320px',
        boxShadow: 'none',
        backgroundColor: 'brand.extraLight',
        '.poster': {
            backgroundColor: 'brand.medium',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            w: '270px',
            h: '370px',
            borderRadius: '10px',
        },
        '.completed-tag': {
            mt: '20px',
            backgroundColor: '#C8CEE7',
            borderRadius: 'full',
            color: 'brand.extraHeavy',
        },
        '.program-name': {
            my: '10px',
            fontWeight: '500',
            fontSize: '24px',
            color: 'brand.extraHeavy',
        },
    },
}
const UserPrograms = {
    baseStyle,
}

export default UserPrograms
