import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { DefaultContext, FetchPolicy } from '@apollo/client'
import { ApolloClient, createHttpLink, from, fromPromise, InMemoryCache } from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { openToast } from '~components/toast'
import { routes } from '~components/sielbleu/utils/routes'
import { RefreshTokenDocument } from '~graphql/types'
import { ISOLocalDateFormat } from '~components/sielbleu/utils/date'
import { DEFAULT_TIMEZONE } from '~components/sielbleu/utils/const'
import { cookieName, removeCookie, setCookie } from '~lib/cookies'
import { deleteCookie, getCookie, getCookies } from 'cookies-next'
import { redirect } from 'next/dist/server/api-utils'
import { isMobileDevice } from '~lib/responsive'
import jwt_decode from 'jwt-decode'

export const isClientSide = (): boolean => typeof window !== 'undefined'

const GRAPHQL_ERROR = 'graphql_error'

export const CloudErrors = Object.freeze({
    AUTHENTICATION: 10001,
    AUTHORIZATION: 10002,
    VALIDATION: 10003,
    RATE_LIMIT: 10004,
    INTERNAL: 10005,
    GRAPHQL: 10006,
})

export class RefreshTokenError extends Error {}

export const getRefreshToken = async (context: Record<string, any>) => {
    if (isClientSide()) {
        if (!getCookie(cookieName('refresh_token'))) {
            throw new RefreshTokenError()
        }

        const response = await fetch(routes.api.refresh, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refresh_token: getCookie(cookieName('refresh_token')) }),
        })

        const data = await response.json()
        if (data.success == false) {
            throw new RefreshTokenError()
        }

        return data.accessToken
    }

    if (!isClientSide() && context && context.nextRequest) {
        const options = { req: context.nextRequest, res: context.nextResponse }

        if (!context.nextRequest?.cookies[cookieName('refresh_token')]) {
            throw new RefreshTokenError()
        }

        try {
            const { refreshToken } = await mutate(RefreshTokenDocument, {
                token: '' + context.nextRequest?.cookies[cookieName('refresh_token')],
            })

            const { exp = 0 } = jwt_decode<{ exp: number }>(refreshToken.accessToken)
            const tokenExpiration = exp ? new Date(exp * 1000) : null

            setCookie(options.res, cookieName('access_token'), refreshToken.accessToken, tokenExpiration)
            setCookie(options.res, cookieName('refresh_token'), refreshToken.refreshToken)

            return refreshToken.accessToken
        } catch (error) {
            console.error('Error during mutation:', error)
            throw new RefreshTokenError()
        }
    }

    return null
}

const retryLink = new RetryLink({
    delay: {
        initial: 300,
        max: 5000,
        jitter: true,
    },
    attempts: {
        max: 3,
        retryIf: (error) => !!error,
    },
})

const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {},
        },
    },
})

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
        const context = operation.getContext()
        for (let err of graphQLErrors) {
            switch (err.extensions.code) {
                case CloudErrors.AUTHENTICATION:
                    return fromPromise(
                        getRefreshToken(context).catch((reason) => {
                            if (isClientSide()) {
                                deleteCookie(cookieName('access_token'))
                                deleteCookie(cookieName('refresh_token'))
                                deleteCookie(cookieName('me'))
                                // reload page to let middleware redirect to login page
                                window.location.reload()
                                return
                            }

                            // Server side
                            removeCookie(context.nextResponse, cookieName('access_token'))
                            removeCookie(context.nextResponse, cookieName('refresh_token'))
                            removeCookie(context.nextResponse, cookieName('me'))
                            throw reason
                        })
                    ).flatMap((accessToken) => {
                        operation.setContext({
                            headers: {
                                ...context.headers,
                                authorization: `Bearer ${accessToken}`,
                            },
                        })
                        return forward(operation)
                    })
            }
        }
        graphQLErrors.map(({ message, locations, path, extensions }) => {
            isClientSide() &&
                openToast({ id: GRAPHQL_ERROR, title: (extensions?.localizedDescription as string) || message })
        })
    }
})

const tokenMiddleware = setContext((_, { headers, nextRequest }) => {
    const cookies = isClientSide() ? getCookies() : nextRequest?.cookies
    const access_token = cookies && cookies[cookieName('access_token')]
    return {
        headers: {
            ...headers,
            authorization: access_token ? `Bearer ${access_token}` : '',
        },
    }
})

const languageAndDateMiddleware = setContext(async (_, { headers, nextRequest }) => {
    const cookies = isClientSide() ? getCookies() : nextRequest?.cookies
    const userAgent = isClientSide() ? navigator.userAgent : nextRequest?.headers['user-agent']
    const lang = cookies?.NEXT_LOCALE
    const device = isMobileDevice(userAgent || '') ? 'Mobile' : 'Desktop'

    const timezone =
        cookies?.timezone || (isClientSide() ? Intl.DateTimeFormat().resolvedOptions().timeZone : DEFAULT_TIMEZONE)

    return {
        headers: {
            ...headers,
            ...(lang && { 'accept-language': lang }),
            ...(timezone && { 'client-timezone': timezone, 'client-date': ISOLocalDateFormat(timezone) }),
            ...(device ? { 'x-client-platform': `${device} Web` } : {}),
        },
    }
})

export const apolloClient = new ApolloClient({
    link: from([
        tokenMiddleware,
        languageAndDateMiddleware,
        retryLink,
        errorLink,
        createHttpLink({
            uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
        }),
    ]),
    cache,
    connectToDevTools: false,
})

export async function query<Data = any, Variables extends object = {}>(
    query: TypedDocumentNode<Data, Variables>,
    locale: string | undefined,
    variables: Variables = {} as Variables,
    context: DefaultContext = {}
): Promise<Data> {
    const fetchPolicy: FetchPolicy = 'no-cache'

    console.log(`%s Performing request on cloud (locale: ${locale})`, '\u2601\uFE0F ')
    const { data, errors } = await apolloClient.query({
        query,
        variables,
        fetchPolicy,
        context: {
            ...context,
            headers: {
                'Accept-Language': locale || 'en',
                ...context.headers,
            },
        },
    })

    if (errors?.length) {
        throw errors[0]
    }

    // We cast as data as when known that an error occurs, an error should be thrown
    // So data should not be undefined or null
    return data as Data
}

export async function mutate<Data = any, Variables extends object = {}>(
    mutation: TypedDocumentNode<Data, Variables>,
    variables: Variables = {} as Variables,
    context: DefaultContext = {}
): Promise<Data> {
    const { data, errors } = await apolloClient.mutate({
        mutation,
        variables,
        context,
    })

    if (errors?.length) {
        throw errors[0]
    }

    // We cast as data as when known that an error occurs, an error should be thrown
    // So data should not be undefined or null
    return data as Data
}
