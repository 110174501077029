import { SystemStyleFunction } from '@chakra-ui/theme-tools'
import { ComponentStyleConfig } from '@chakra-ui/react'

const linkVariant: SystemStyleFunction = (props) => {
    return {
        color: 'inherit',
        _hover: {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    }
}
const dataVariant: SystemStyleFunction = () => {
    return {
        color: 'brand.medium',
        fontWeight: 'bold',
        fontSize: ['35px', '35px', '45px'],
    }
}

const labelVariant: SystemStyleFunction = () => {
    return {
        color: 'brand.medium',
        fontWeight: 'bold',
    }
}
const variants = {
    link: linkVariant,
    data: dataVariant,
    label: labelVariant,
}

const baseStyle = {
    fontFamily: 'roboto',
}

const Text: ComponentStyleConfig = {
    variants,
    baseStyle,
}

export default Text
