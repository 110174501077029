const baseStyle = {
    '.lock': {
        position: 'absolute',
        top: {
            base: 2,
            lg: 5,
        },
        right: 5,
        zIndex: 2,
    },
    '.container': {
        '.video-sessions': {
            mw: '100%',
            display: 'flex',
            alignItems: {
                base: 'center',
            },
            flexDirection: {
                base: 'column',
                md: 'row',
            },
            gap: '30px',
            mb: '20px',
            flexWrap: 'wrap',
            '.video-session': {
                _before: {
                    content: `""`,
                    borderRadius: '5px',
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: '0',
                    left: '0',
                    bg: 'gray.900',
                    opacity: '0.2',
                },
                cursor: 'pointer',
                _hover: {
                    opacity: '0.8',
                },
                w: {
                    base: '100%',
                    md: '47%',
                    xl: '31%',
                },
                borderRadius: '5px',
                padding: '25px',
                position: 'relative',
                h: {
                    base: '252px',
                    xl: '282px',
                },
                '.text': {
                    textShadow: '0 0 1em rgba(0,0,0,0.7)',
                    position: 'absolute',
                    bottom: '20px',
                    color: 'white',
                    pr: '25px',
                    '.name': {
                        fontSize: '30px',
                        textTransform: 'capitalize',
                        fontWeight: '500',
                    },
                    '.duration': {
                        fontSize: '18px',
                    },
                },
            },
        },
    },
}

const VideoSessions = {
    baseStyle,
}

export default VideoSessions
