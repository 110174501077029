import { SystemStyleFunction } from '@chakra-ui/theme-tools'
import { ComponentStyleConfig } from '@chakra-ui/react'

const primaryVariant: SystemStyleFunction = (props) => {
    return {
        color: 'brand.medium',
        '.active': {
            fontWeight: 'bold',
        },
    }
}

const navVariant: SystemStyleFunction = (props) => {
    return {
        '.active': {
            color: 'brand.medium',
            fontWeight: 'bold',
        },
    }
}
const asButtonVariant: SystemStyleFunction = (props) => {
    return {
        ml: {
            base: '10px',
            md: 0,
        },
        w: 'fit-content',
        borderRadius: '5px',
        fontWeight: 'bold',
        bg: 'brand.medium',
        color: 'white',
        fontSize: {
            base: '16px',
            md: '20px',
        },
        textTransform: 'uppercase',
        px: {
            base: '16px',
            md: '26px',
        },
        py: {
            base: '6px',
            md: '11px',
        },
        _hover: {
            bg: 'brand.heavy',
            textDecoration: 'none',
        },
        a: {
            _hover: {
                textDecoration: 'none',
            },
        },
    }
}

const variants = {
    primary: primaryVariant,
    nav: navVariant,
    button: asButtonVariant,
}

const baseStyle = {
    fontFamily: 'roboto',
}

const Link: ComponentStyleConfig = {
    baseStyle,
    variants,
}

export default Link
